import storage from './storage'

function IntegrationStorage(date) {
  this.storageKey = 'integration_date'
  this.integrateDate = date

  this.date = function (date) {
    this.integrateDate = date
    return this
  }

  this.get = function () {
    const storedIntegration = storage(this.storageKey).recover()

    return storedIntegration || null
  }

  this.clear = function () {
    storage(this.storageKey).clear()
  }

  this.save = function () {
    return storage(this.storageKey).save(this.integrateDate)
  }
}

export default (date) => new IntegrationStorage(date)
