<script>
import { mapGetters, mapActions } from 'vuex'
import Fieldview from '@/pages/integrations/fieldview'

export default {
  components: {
    Fieldview,
  },

  computed: {
    ...mapGetters('integrations', ['integrations']),
  },

  async created() {
    await this.fetchIntegrations()
  },

  methods: {
    ...mapActions('integrations', ['fetchIntegrations']),
  },
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <fieldview
          :fieldview="integrations.fieldview"
          v-if="integrations.fieldview"
        ></fieldview>
      </v-col>
    </v-row>
  </div>
</template>
