<script>
import { mapActions } from 'vuex'
import Login from '@/pages/integrations/fieldview/login'
import Integrated from '@/pages/integrations/fieldview/integrated'
import Error from '@/pages/integrations/fieldview/error'
import { logEvent, events } from '@/services/analytics'
import integrationStorage from '@/services/storage/integrationStorage'
import moment from 'moment'

export default {
  props: {
    fieldview: { type: Object, default: null },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  status: {
    disabled: 0,
    enabled: 1,
    exceeded: 2,
    error: 3,
  },

  components: {
    Login,
    Integrated,
    Error,
  },

  async created() {
    const { code } = this.$route.query
    if (code) {
      await this.integrationFieldview({ code })
    }
  },

  computed: {
    isIntegrated() {
      return (
        !this.isLoading &&
        this.fieldview?.integrated &&
        this.fieldview?.status == this.$options.status?.enabled
      )
    },

    isLogin() {
      return (
        !this.isLoading &&
        !this.fieldview?.integrated &&
        this.fieldview?.status == this.$options.status?.disabled
      )
    },

    isError() {
      return (
        !this.isLoading &&
        this.fieldview?.integrated &&
        (this.fieldview?.status == this.$options.status?.exceeded ||
          this.fieldview?.status == this.$options.status?.error)
      )
    },
  },

  methods: {
    ...mapActions('integrations', ['disableFieldview', 'fetchIntegrations']),

    async integrationFieldview(code) {
      try {
        this.isLoading = true
        const { data } = await this.$api.integrations.fieldview(code)

        if (data.integrated) {
          logEvent(events.integration.visitedPage_success)
          const now = moment().format()
          integrationStorage(now).save()
          const query = Object.assign({}, this.$route.query)
          delete query.code
          this.$router.replace({ query })
          await this.fetchIntegrations()
        }

        this.isLoading = false
      } catch (e) {
        await this.fetchIntegrations()
        this.isLoading = false
      }
    },

    async syncManual() {
      try {
        logEvent(events.integration.clickedButton_tryAgain)
        this.isLoading = true
        await this.$api.integrations.syncFieldview()
        await this.fetchIntegrations()
        this.isLoading = false
        this.$root.$emit(
          'notify',
          'success',
          this.$t('integration.sync_success')
        )
      } catch (e) {
        await this.fetchIntegrations()
        this.isLoading = false
      }
    },

    async disableIntegration() {
      this.isLoading = true
      logEvent(events.integration.clickedButton_unsync)
      await this.disableFieldview()
      this.isLoading = false
    },
  },
}
</script>

<template>
  <v-card class="fieldview-container">
    <v-row
      v-if="isLoading"
      align-content="center"
      justify="center"
      class="pa-15"
    >
      <v-col cols="12" class="subtitle-1 text-center">
        {{ $t('loading') }}
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular indeterminate color="blue"></v-progress-circular>
      </v-col>
    </v-row>
    <integrated
      v-if="isIntegrated"
      @syncManual="syncManual"
      @disableIntegration="disableIntegration"
    ></integrated>
    <login :url="fieldview.urlLogin" v-if="isLogin"></login>
    <error
      v-if="isError"
      :error="fieldview.status"
      @syncManual="syncManual"
    ></error>
  </v-card>
</template>

<style scoped>
.fieldview-container {
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: 0px 2px 9px rgba(230, 233, 230, 0.3) !important;
}
</style>
