<script>
import { logEvent, events } from '@/services/analytics'

export default {
  props: {
    error: { type: Number, required: true, default: 3 },
  },

  status: {
    exceeded: 2,
    error: 3,
  },

  mounted() {
    logEvent(events.integration.visitedPage_exceededArea)
  },

  computed: {
    isExceeded() {
      return this.error == this.$options.status?.exceeded
    },

    getErrorTitle() {
      if (this.isExceeded) {
        return this.$t('integration.error.title_exceeded')
      }
      return this.$t('integration.error.title')
    },

    getErrorDescription() {
      if (this.isExceeded) {
        return this.$t('integration.error.description_exceeded')
      }
      return this.$t('integration.error.description')
    },
  },

  methods: {
    syncManual() {
      this.$emit('syncManual')
    },
  },
}
</script>

<template>
  <v-row>
    <v-col class="fieldview-container-description">
      <v-img
        width="244"
        height="31"
        style="margin-left: 16px; filter: grayscale(100%)"
        src="img/digifarmz_fieldview.jpg"
      ></v-img>
      <v-card-title class="fieldview-title-text">
        {{ getErrorTitle }}
      </v-card-title>
      <v-card-text class="fieldview-text">
        {{ getErrorDescription }}
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col v-if="isExceeded">
            <v-btn
              class="fieldview-integration-button"
              color="#39AF49"
              dark
              link
              exact
              target="_blank"
              href="https://api.whatsapp.com/send?phone=5551993519481&text=Preciso%20aumentar%20minha%20%C3%A1rea%2C%20pois%20n%C3%A3o%20estou%20conseguindo%20integrar%20com%20o%20Fieldview"
            >
              {{ $t('integration.error.upgrade') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-if="isExceeded"
              class="fieldview-tryagain-button"
              color="#5b6459"
              text
              @click="syncManual()"
            >
              {{ $t('integration.error.try_again') }}
            </v-btn>
            <v-btn
              v-if="!isExceeded"
              class="fieldview-tryagain-button"
              color="#39AF49"
              dark
              @click="syncManual()"
            >
              <font-awesome-icon class="fa-md mr-2" icon="redo" />
              {{ $t('integration.error.try_again') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-col>
    <v-col class="py-0">
      <v-img
        width="479"
        height="100%"
        style="filter: grayscale(100%)"
        src="img/integracao-fieldview.jpg"
      ></v-img>
    </v-col>
  </v-row>
</template>

<style scoped>
.fieldview-title-text {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: #1a2b46;
  word-break: normal;
}

.fieldview-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #51594f;
}

.fieldview-container-description {
  padding: 40px 0 30px 100px;
}

.fieldview-integration-button {
  text-transform: none;
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
}

.fieldview-tryagain-button {
  text-transform: none;
  text-decoration: underline;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
}
</style>
